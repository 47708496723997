import ReactDOM from "react-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "components/Button";
import styles from "./styles.module.scss";

// eslint-disable-next-line no-unused-vars
const Dialog = styled(({ shrink, ...other }) => <Box {...other} />)((props) => ({
  [props.theme.breakpoints.down("lg")]: {
    width: props.shrink ? "85%" : "100%",
  },
}));

const responsiveParams = { xs: "100%", sm: "80%", md: "50%" };

const Modal = ({
  cancelButtonProps,
  cancelButtonText,
  children,
  classes,
  className,
  container,
  disableBackdropClick,
  disableGutters,
  doneButtonProps,
  doneButtonText,
  fullWidth,
  message,
  onCancelButtonClick,
  onDoneButtonClick,
  open,
  showCloseButton,
  shrink,
  subtitle,
  sx,
  title,
  wide,
}) => {
  if (!open) return null;

  const handleBackdropClick = (e) => {
    if (disableBackdropClick || e.target !== e.currentTarget) return;
    onCancelButtonClick();
  };

  const showCancelButton = !showCloseButton && onCancelButtonClick;

  const modal = (
    <div className={styles.backdrop} onClick={handleBackdropClick} style={sx}>
      <Dialog
        alignItems="center"
        className={clsx(
          styles.modal,
          {
            [styles.wide]: wide,
            [styles.shrink]: shrink,
            [styles.contained]: container,
          },
          className,
        )}
        role="dialog"
        shrink={shrink}
      >
        <Box
          className={clsx(
            styles.container,
            {
              [styles.containerShrink]: shrink,
            },
            classes.container,
          )}
          maxWidth={responsiveParams}
          minWidth={fullWidth ? "100%" : "358px"}
          width={shrink ? undefined : responsiveParams}
        >
          {(title || subtitle) && (
            <header className={styles.header}>
              {title && (
                <Typography color="textPrimary" component="h1" variant="h6">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Box my={1}>
                  <Typography color="textSecondary" component="h2" variant="body1">
                    {subtitle}
                  </Typography>
                </Box>
              )}
              {showCloseButton && onCancelButtonClick && (
                <IconButton
                  aria-label="close"
                  className={styles.closeButton}
                  onClick={onCancelButtonClick}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </header>
          )}

          <div className={clsx(styles.content, { [styles.contentWithoutGutters]: disableGutters })}>
            {message || children}
          </div>

          {(onDoneButtonClick || onCancelButtonClick) && (
            <Grid container>
              {showCancelButton && (
                <Grid item xs>
                  <Button
                    data-testid="modal-cancel-button"
                    fullWidth
                    onClick={onCancelButtonClick}
                    sx={{ borderRadius: `0 0 ${onDoneButtonClick ? 0 : 1}rem 1rem`, height: "100%" }}
                    variant="secondary"
                    {...cancelButtonProps}
                  >
                    {cancelButtonText}
                  </Button>
                </Grid>
              )}

              {onDoneButtonClick && (
                <Grid item xs>
                  <Button
                    data-testid="modal-done-button"
                    fullWidth
                    onClick={onDoneButtonClick}
                    sx={{ borderRadius: `0 0 1rem ${showCancelButton ? 0 : 1}rem`, height: "100%" }}
                    {...doneButtonProps}
                  >
                    {doneButtonText}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Dialog>
    </div>
  );

  return container ? ReactDOM.createPortal(modal, container) : modal;
};

Modal.propTypes = {
  cancelButtonProps: PropTypes.object,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node,

  classes: PropTypes.object,
  className: PropTypes.string,
  container: PropTypes.instanceOf(Element),
  disableBackdropClick: PropTypes.bool,
  disableGutters: PropTypes.bool,

  doneButtonProps: PropTypes.object,
  doneButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  message: PropTypes.string,
  onCancelButtonClick: PropTypes.func,
  onDoneButtonClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  shrink: PropTypes.bool,
  subtitle: PropTypes.string,

  sx: PropTypes.object,
  title: PropTypes.string,
  wide: PropTypes.bool,
};

Modal.defaultProps = {
  cancelButtonProps: {},
  cancelButtonText: "Cancel",
  children: undefined,
  classes: {},
  className: undefined,
  container: undefined,
  disableBackdropClick: false,
  disableGutters: false,
  doneButtonProps: {},
  doneButtonText: "Done",
  fullWidth: false,
  message: undefined,
  onCancelButtonClick: undefined,
  onDoneButtonClick: undefined,
  showCloseButton: false,
  shrink: false,
  subtitle: undefined,
  sx: {},
  title: undefined,
  wide: false,
};

export default Modal;
