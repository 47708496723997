import { useMemo } from "react";
import PropTypes from "prop-types";
import { OutlinedInput } from "@mui/material";
import FormField from "components/FormField";
import uniqueId from "lodash/uniqueId";

const TextField = ({
  disabled,
  endAdornment,
  error,
  fullWidth,
  helperText,
  id,
  inputProps,
  label,
  name,
  onChange,
  onFocus,
  onMouseDown,
  onTouchEnd,
  placeholder,
  required,
  type,
  value,
  ...rest
}) => {
  const uid = useMemo(() => id || uniqueId("textfield-"), [id]);

  return (
    <FormField
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      id={uid}
      label={label}
      required={required}
    >
      <OutlinedInput
        endAdornment={endAdornment}
        id={uid}
        inputProps={inputProps}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        placeholder={placeholder}
        type={type}
        value={value}
        {...rest}
      />
    </FormField>
  );
};

export const propTypes = {
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,

  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onTouchEnd: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export const defaultProps = {
  disabled: false,
  endAdornment: undefined,
  error: undefined,
  fullWidth: false,
  helperText: undefined,
  id: undefined,
  inputProps: {},
  label: undefined,
  name: undefined,
  onChange: undefined,
  onFocus: undefined,
  onMouseDown: undefined,
  onTouchEnd: undefined,
  placeholder: undefined,
  required: false,
  type: "text",
  value: undefined,
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
