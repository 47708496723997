import { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const Button = forwardRef(
  ({ component, children, href, onClick, fullWidth, className, variant, type, ...props }, ref) => {
    const theme = useTheme();
    const Component = href ? "a" : component;

    return (
      <LoadingButton
        className={className}
        component={Component}
        fullWidth={fullWidth}
        href={href}
        loadingIndicator={<CircularProgress color="inherit" size={24} />}
        onClick={onClick}
        ref={ref}
        sx={theme.components.Button}
        type={Component === "button" ? type : undefined}
        variant={variant}
        {...props}
      >
        <span>{children}</span>
      </LoadingButton>
    );
  },
);

Button.displayName = "Button";

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  className: PropTypes.string,
  component: PropTypes.string,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "secondary", "text"]),
};

Button.defaultProps = {
  children: null,
  className: undefined,
  component: "button",
  fullWidth: false,
  href: undefined,
  onClick: () => {},
  type: "button",
  variant: "contained",
};

export default Button;
