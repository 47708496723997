import React from "react";
import ReactDOM from "react-dom";

const COMPONENT_NAME_ATTR = "data-react-component-name";
const PROPS_ATTR = "data-react-props";
const SERVER_ENV = "data-server-env";
const A11Y_INSPECT_ENVIRONMENTS = ["development", "testing", "staging"];

export default function renderComponents(allComponents, loadAxe = true) {
  const containers = document.querySelectorAll(`[${COMPONENT_NAME_ATTR}]`);

  [].forEach.call(containers, (container) => {
    const componentName = container.getAttribute(COMPONENT_NAME_ATTR);
    const componentExists = Object.keys(allComponents).includes(componentName);

    if (componentExists) {
      const component = allComponents[componentName];
      const propsJson = container.getAttribute(PROPS_ATTR);
      const props = JSON.parse(propsJson);
      const reactComponent = React.createElement(component, props);
      const serverEnv = container.getAttribute(SERVER_ENV);

      if (loadAxe && A11Y_INSPECT_ENVIRONMENTS.includes(serverEnv)) {
        import("@axe-core/react").then((axe) => {
          axe.default(React, ReactDOM, 1000, { rules: [{ id: "region", enabled: false }] });
          ReactDOM.render(reactComponent, container);
        });
      } else {
        ReactDOM.render(reactComponent, container);
      }
    } else {
      throw new Error(`No react component with the name ${componentName} could be found in this context.`);
    }
  });
}
