import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import PropTypes from "prop-types";

const FormField = ({
  centerErrorText,
  children,
  disabled,
  error,
  focused,
  fullWidth,
  helperText,
  id,
  label,
  onClick,
  required,
}) => {
  const hasStringError = error && typeof error === "string";
  const errorProps = centerErrorText ? { sx: { textAlign: "center" } } : {};

  return (
    <FormControl
      disabled={disabled}
      error={!!error}
      focused={focused}
      fullWidth={fullWidth}
      onClick={onClick}
      required={required}
    >
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {children}
      {helperText && !hasStringError && <FormHelperText>{helperText}</FormHelperText>}
      {error && hasStringError && <FormHelperText {...errorProps}>{error}</FormHelperText>}
    </FormControl>
  );
};

const propTypes = {
  centerErrorText: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  focused: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  required: PropTypes.bool,
};

const defaultProps = {
  centerErrorText: false,
  disabled: false,
  error: undefined,
  focused: undefined,
  fullWidth: false,
  helperText: undefined,
  id: undefined,
  label: undefined,
  onClick: () => {},
  required: false,
};

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
